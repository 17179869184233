import React from "react";
import { twMerge } from "tailwind-merge";

export default function PillTabs({ onTabChange, defaultTab, children }) {
  const [activeTab, setActiveTab] = React.useState(defaultTab || 0);

  const handleTabClick = (value) => {
    setActiveTab(value);
    if (onTabChange) {
      onTabChange(value);
    }
  };
  return (
    <div>
      <div className="container sm:hidden pb-sm">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block gn-text-sm w-full border-1 border-white rounded bg-transparent text-white focus:border-purple"
          defaultValue={children[defaultTab || 0].props.label}
          onChange={(e) =>
            handleTabClick(Number(e.target.children[e.target.selectedIndex].id))
          }
        >
          {children.map((child, index) =>
            child.props.hidden ? null : (
              <option key={child.props.label} id={index}>
                {child.props.label}
              </option>
            ),
          )}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex justify-center space-x-lg" aria-label="Tabs">
          {children.map((child, index) =>
            child.props.hidden ? null : (
              <button
                type="button"
                key={`${child.props.label.toLowerCase()}-${index.toString()}`}
                onClick={() => handleTabClick(index)}
                className={twMerge(
                  activeTab === index
                    ? "border-b border-gray-200 text-white"
                    : "border-b border-gray-900 text-gray-300 hover:text-mint",
                  "transition-colors px-sm py-xs gn-text-sm focus:outline-none",
                )}
                aria-current={activeTab === index ? "page" : undefined}
              >
                {child.props.label}
              </button>
            ),
          )}
        </nav>
      </div>
      <div className="mt-xs">{children[activeTab]}</div>
    </div>
  );
}

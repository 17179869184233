import React from "react";
import PropTypes from "prop-types";
import { SelectorIcon } from "@heroicons/react/solid";
import { Columns, Select, Toast } from "@gonoodle/gn-universe-ui";
import {
  PAGES_TYPES,
  SECTIONS,
  SECTIONS_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";

import { useContentTagVideosQuery } from "../../hooks";
import { SPONSORED_PARTNER_TYPES } from "../../constants";
import VideoTile from "../VideoTile";
import LayoutRenderer from "../LayoutRenderer";

const sort = (videos, filterType) => {
  const result = [...videos];

  if (filterType === 1) {
    result.sort(
      (a, b) =>
        new Date(b.publishStartsAt).getTime() -
        new Date(a.publishStartsAt).getTime(),
    );
  }
  // ascending title sort
  else if (filterType === 2) {
    result.sort((a, b) =>
      a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
    );
  }
  // descending title sort
  else if (filterType === 3) {
    result.sort((a, b) =>
      a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1,
    );
  }
  return result;
};

const ShelvesTab = ({ tag }) => {
  const alert = Toast.useAlert();
  const [sortPreference, setSortPreference] = React.useState({
    id: 1,
    name: "Most Recent",
  });
  const { videos, isLoadingVideos, videosError } = useContentTagVideosQuery(
    tag.id,
  );

  const sortedVideos = React.useMemo(() => {
    if (!videos) return [];
    return sort(videos, sortPreference.id);
  }, [sortPreference.id, videos]);

  // TODO: Remove custom mapping once API is updated
  const layout = React.useMemo(() => {
    if (!tag.customShelves) return [];

    return tag.customShelves.map((shelf) => ({
      key: `CustomShelf-${shelf.id}`,
      type: "CustomShelf",
      fetcher: [
        {
          params: {
            id: shelf.id,
          },
          resolver: "custom_shelf",
        },
      ],
      templateOptions: {
        title: shelf.internalTitle,
        displayTitle: shelf.displayTitle,
        description: shelf.displayDescription,
      },
    }));
  }, [tag.customShelves]);

  if (videosError) {
    alert.error("Something went wrong. Please reload page.");
    return null;
  }

  return (
    <>
      <div className="container pt-sm pb-lg">
        <LayoutRenderer layout={layout} />
      </div>

      <div className="flex justify-between items-center container">
        <h2 className="gn-text font-bold text-gray-200 capitalize">
          All Videos
        </h2>

        <Select
          onChange={setSortPreference}
          inputProps={{
            endAdornment: (
              <SelectorIcon className="h-input-icon text-gray-300 " />
            ),
          }}
          leadingText="Sort: "
          buttonClassNames="border h-button-sm border-gray-500 gn-text-xs px-sm pr-lg"
          focusRingClassNames=" "
          optionClassNames="pl-lg"
          optionTextClassNames="gn-text-xs font-semibold"
          checkmark
          mode="dark"
          value={sortPreference}
          labelId="video-sort"
        />
      </div>
      <Columns loading={isLoadingVideos}>
        {sortedVideos.map((video) => (
          <VideoTile
            key={video.id}
            id={video.id}
            slug={video.slug}
            title={video.title}
            description={video.description}
            images={video.images}
            duration={video.duration}
            sponsor={{
              expiration: video.attribution?.expiration,
              type: video.attribution?.type,
            }}
            impressionsProps={
              video.attribution?.type ===
              SPONSORED_PARTNER_TYPES.PROMOTIONAL_ACTIVITY
                ? {
                    sourcePage: `${tag.name} landing page`,
                    sourcePageType: PAGES_TYPES.LANDING,
                    sourceName: SECTIONS.ALL_VIDEOS,
                    sourceElement: SECTIONS_TYPES.SHELF,
                  }
                : null
            }
            linkParams={{
              sourceId: tag.id,
              sourcePage: `${tag.name} landing page`,
              sourcePageType: PAGES_TYPES.LANDING,
              sourceName: SECTIONS.ALL_VIDEOS,
              sourceElement: SECTIONS_TYPES.SHELF,
            }}
          />
        ))}
      </Columns>
    </>
  );
};

ShelvesTab.propTypes = {
  tag: PropTypes.shape({}).isRequired,
};

export default ShelvesTab;

import React from "react";
import PropTypes from "prop-types";
import { Header, Columns } from "@gonoodle/gn-universe-ui";
import { PAGES_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";

import { createImgSources } from "../utils/imgSources";
import {
  IMPRESSION_TYPES,
  IMPRESSION_PLACEMENT_TYPES,
  HEADER_SIZES,
} from "../constants";
import { useImpression } from "../hooks";
import TextTile from "./TextTile";

const ContentTags = ({ tag }) => (
  <>
    <Header
      bgSources={tag.images?.headerImage}
      title={tag.name}
      mode={tag.textTheme}
      size={HEADER_SIZES[tag.headerImageSize]}
      description={tag.description}
      bgColor="mint"
    />
    <Columns cols={4}>
      {tag?.secondaryContentTags?.map((secondaryTag) => (
        <Tile
          key={secondaryTag.slug}
          tag={secondaryTag}
          format={tag.tileSize}
        />
      ))}
    </Columns>
  </>
);

const Tile = ({ tag, format }) => {
  const adProduct = tag.adProductPartners[0];
  const tileSource = createImgSources(
    adProduct?.images?.partnerListingPageTile,
    tag.images.tileImage,
  );

  const partnerTileInViewRef = useImpression({
    properties: {
      impression_type: IMPRESSION_TYPES.LOGO,
      placement: IMPRESSION_PLACEMENT_TYPES.LISTING_PAGE_TILE,
      ad_product_type: adProduct?.type,
      partner_name: adProduct?.partnerName,
      partner_id: adProduct?.partnerId,
      ad_product_partner_id: adProduct?.id,
      content_hub_type: tag?.type,
      content_hub_name: tag?.name,
    },
    enabled: !!adProduct,
  });

  return (
    <TextTile
      id={tag.id}
      slug={tag.slug}
      name={tag.name}
      tile={tileSource}
      format={format}
      ref={partnerTileInViewRef}
      linkParams={{
        sourcePage: `${tag.name} listing page`,
        sourcePageType: PAGES_TYPES.LISTING,
      }}
    />
  );
};

ContentTags.propTypes = {
  tag: PropTypes.shape({
    availableTabs: PropTypes.shape({
      featured: PropTypes.bool,
      videos: PropTypes.bool,
      printables: PropTypes.bool,
      blogPosts: PropTypes.bool,
    }),
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.shape({
      header: PropTypes.shape({
        "regular@1x": PropTypes.string,
        "regular@2x": PropTypes.string,
        "regular@3x": PropTypes.string,
      }),
    }),
  }).isRequired,
};

Tile.propTypes = {
  tag: PropTypes.shape({
    availableTabs: PropTypes.shape({
      featured: PropTypes.bool,
      videos: PropTypes.bool,
      printables: PropTypes.bool,
      blogPosts: PropTypes.bool,
    }),
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    images: PropTypes.shape({
      header: PropTypes.shape({
        "regular@1x": PropTypes.string,
        "regular@2x": PropTypes.string,
        "regular@3x": PropTypes.string,
      }),
    }),
  }).isRequired,
  format: PropTypes.string,
};

Tile.defaultProps = {
  format: "square",
};

export default ContentTags;
